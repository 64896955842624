import './SearchFilters.scss';
import Checkbox from '../../Checkbox/Checkbox';
import { DiscoveryFilterSortBy } from '../../../core/backend/enums';
import { ForwardedRef, forwardRef } from 'react';

export interface ISearchFilter {
  sortBy?: DiscoveryFilterSortBy;
}

export interface IProps {
  className?: string;
  values?: ISearchFilter;
  onChange: (filters: ISearchFilter) => void;
}

const SearchFilters = forwardRef(
  (
    { className = '', values, onChange }: IProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    function handleChangeFilterValue<K extends keyof ISearchFilter>(
      filter: K,
      value: ISearchFilter[K]
    ) {
      return () => {
        if (values[filter] === value) return;

        onChange({ [filter]: value });
      };
    }

    return (
      <div ref={ref} className={`SearchFilters ${className}`}>
        <div className='SearchFilters__content'>
          <div className='SearchFilters__contentBlock'>
            <h3 className='SearchFilters__contentBlockHeader'>Sort by</h3>
            <div className='SearchFilters__contentBlockRow'>
              <div className='SearchFilters__contentBlockLabel'>Newest</div>
              <Checkbox
                value={values.sortBy === DiscoveryFilterSortBy.Newest}
                onChange={handleChangeFilterValue(
                  'sortBy',
                  DiscoveryFilterSortBy.Newest
                )}
              />
            </div>

            <div className='SearchFilters__contentBlockRow'>
              <div className='SearchFilters__contentBlockLabel'>
                Most Popular
              </div>
              <Checkbox
                value={values.sortBy === DiscoveryFilterSortBy.MostPopular}
                onChange={handleChangeFilterValue(
                  'sortBy',
                  DiscoveryFilterSortBy.MostPopular
                )}
              />
            </div>

            <div className='SearchFilters__contentBlockRow'>
              <div className='SearchFilters__contentBlockLabel'>Most Views</div>
              <Checkbox
                value={values.sortBy === DiscoveryFilterSortBy.MostViews}
                onChange={handleChangeFilterValue(
                  'sortBy',
                  DiscoveryFilterSortBy.MostViews
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SearchFilters;

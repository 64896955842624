import React from 'react';
import { generatePrivateMessage } from '../../core/helpers';
import ExperienceModel from '../../core/models/ExperienceModel';
import appToast from '../../core/toast';
import { useStores } from '../../hooks';
import GigCard from '../Cards/GigCard/GigCard';
import './GigGallery.scss';

export interface IVideo {
  id: number;
  thumbnail: string;
  url: string;
}

interface IProps {
  data: ExperienceModel[];
  itemCount?: number;
  placeholder?: React.ReactNode;
  skipPrivateValidation?: boolean;
  onPressVideo: (index: number, experience?: ExperienceModel) => void;
}

const GigGallery = ({
  data,
  itemCount,
  skipPrivateValidation = false,
  placeholder,
  onPressVideo
}: IProps) => {
  const { userStore } = useStores();
  const onPress =
    (index: number, experience?: ExperienceModel) =>
    (e: React.MouseEvent): void => {
      e.preventDefault();

      if (
        !skipPrivateValidation &&
        experience.isPrivate &&
        userStore?.user?.id !== experience.creator.id
      ) {
        return appToast.showError(
          generatePrivateMessage(experience.isOneTimeEvent)
        );
      }

      onPressVideo(index, experience);
    };

  const items = itemCount ? data.slice(0, itemCount) : data;

  return (
    <div className='GigGallery container'>
      {!items || items?.length === 0 ? (
        placeholder ?? (
          <div className='GigGallery__noExperiences'>
            There are no experiences.
          </div>
        )
      ) : (
        <div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-4'>
          {items.map((gig, idx) => {
            return (
              <GigCard
                key={`gig-${gig.id}-${Math.random()}`}
                gig={gig}
                onPress={onPress(idx, gig)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GigGallery;

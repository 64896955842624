import dayjs from 'dayjs';
import LazyLoad from 'react-lazyload';
import ExperienceModel from '../../../core/models/ExperienceModel';
import { ReactComponent as HooplaLogo } from '../../../images/logo_sm.svg';
import EventBadge from '../../EventBadge/EventBadge';
import ViewsBadge from '../../ViewsBadge/ViewsBadge';
import './GigCard.scss';
import classNames from 'classnames';
import CreatorBadge from '../../CreatorBadge/CreatorBadge';
import { isMobileDevice } from '../../../core/helpers';
import HoverVideoPlayer from '../../HoverVideoPlayer/HoverVideoPlayer';
import { DATE_FORMAT, TBA_MESSAGE } from '../../../core/consts';
import usePreloadVideoInView from '../../../hooks/usePreloadVideoInView';

interface IProps {
  gig: ExperienceModel;
  onPress?: React.MouseEventHandler;
  className?: string;
  colClass?: string;
}

const GigCard = ({ gig, onPress, className = '', colClass = '' }: IProps) => {
  const url = gig?.mediaFiles[0]?.isVideo ? gig?.mediaFiles[0]?.url : null;
  const { viewRef } = usePreloadVideoInView({ url });

  return (
    <div ref={viewRef} className={`d-flex ${colClass}`}>
      <div className={`GigCard col ${className}`}>
        <div className='GigCard__header'>
          <HoverVideoPlayer
            className='GigCard__hoverVideo'
            videoClassName='GigCard__video'
            videoSrc={gig?.thumbnails?.video}
            pausedOverlay={
              gig?.thumbnails?.static ? (
                <LazyLoad height='100%' once={true} offset={150}>
                  <img
                    src={gig.thumbnails.static || gig.thumbnails.animated}
                    alt={gig.name}
                    className='GigCard__image'
                  />
                </LazyLoad>
              ) : (
                <div className='GigCard__placeholder'>
                  <HooplaLogo />
                </div>
              )
            }
            onClick={onPress}
          />

          {gig.isPrivate && (
            <EventBadge
              type={!gig.isOneTimeEvent ? 'private' : 'private_event'}
              className='GigCard__badge'
            />
          )}

          <div className='GigCard__bottomContainer'>
            <CreatorBadge className='GigCard__creator' user={gig.creator} />
            <ViewsBadge
              large={!isMobileDevice()}
              className='GigCard__views'
              viewsCount={gig.videoViews}
              reverse
            />
          </div>
        </div>
        <div className='GigCard__body'>
          {gig.isOneTimeEvent && (
            <div
              className={classNames('GigCard__eventDate', {
                'GigCard__eventDate--closed': gig.isEventClosed
              })}
            >
              {`${dayjs
                .utc(gig.eventStartDate + gig?.eventTimezoneOffsetInMillis)
                .format(DATE_FORMAT)} ${gig?.dateTimezone}`}
            </div>
          )}
          <h3 className='GigCard__title' onClick={onPress}>
            {gig.name}
          </h3>
          <div className='GigCard__details'>
            {gig.isOneTimeEvent && gig.isSoldOut && !gig.isEventClosed && (
              <div className='GigCard__soldOut'>Sold Out</div>
            )}

            {!gig.isSoldOut && !gig.isEventClosed && (
              <div className='GigCard__price'>
                <div className='GigCard__currentPrice'>{gig.priceLabel}</div>
                {!gig.isFree && gig.discount > 0 && (
                  <div className='GigCard__normalPrice'>
                    {gig.formattedDiscount}
                  </div>
                )}
              </div>
            )}
            <div className='GigCard__location'>
              {gig.isVirtual
                ? 'Virtual'
                : gig.isToBeAnnounced
                ? gig.formattedCityState || TBA_MESSAGE
                : gig.formattedCityState}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GigCard;

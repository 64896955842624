import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import GigGallery from '../../components/GigGallery/GigGallery';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import { useSearch, useStores } from '../../hooks';
import { masterSearch } from '../../core/api';
import MixpanelTracking from '../../core/services/MixpanelTracking';
import { TrackingEvents } from '../../core/enums';
import VideoGallery from '../../components/VideoGallery/VideoGallery';
import VideoCard from '../../components/Cards/VideoCard/VideoCard';
import PlaceHolderCard from '../../components/PlaceHolderCard/PlaceHolderCard';
import DataList from '../../components/DataList/DataList';
import UserCard from '../../components/UserCard/UserCard';
import Section from '../../components/Section/Section';
import { VendorViewModel } from '../../core/backend/models';
import appToast from '../../core/toast';
import { DEFAULT_ERROR_MESSAGE } from '../../core/validators';
import { ICoordinates, IVideoModel } from '../../core/types';
import { useExperienceModal } from '../../components/Modal/ExperienceModal/useExperienceModal';
import { useVideoModal } from '../../components/Modal/VideoModal/useVideoModal';
import GroupedVideoModel from '../../core/models/GroupedVideoModel';
import ExperienceModel from '../../core/models/ExperienceModel';
import useQueryParams from '../../hooks/useQueryParams';
import MasterFilter, {
  IMasterFilterSearchPayload
} from '../../components/MasterFilter/MasterFilter';
import './Search.scss';

const EmptyList = <PlaceHolderCard label='Search result will be shown here.' />;

interface ISearchHolder {
  all?: null;
  experiences?: ExperienceModel[];
  events?: ExperienceModel[];
  clips?: IVideoModel[];
  people?: VendorViewModel[];
  hashtags?: {
    name: string;
    mediaFiles: IVideoModel[];
    events: ExperienceModel[];
  }[];
}

interface ISearchContent {
  [key: string]: React.ReactNode;
}

const Search = () => {
  const { locationStore } = useStores();
  const { openExperienceModal } = useExperienceModal();
  const { openVideoModal } = useVideoModal();
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<ISearchHolder>({
    all: null,
    events: [],
    experiences: [],
    clips: [],
    hashtags: [],
    people: []
  });
  const { searchText, debouncedSearchText, onSearchChange } = useSearch(
    '',
    600,
    3
  );
  const queryParams = useQueryParams();
  const tab = queryParams.get('t');
  const [searchType, setSearchType] = useState<keyof ISearchHolder>(
    (tab as keyof ISearchHolder) || 'all'
  );
  const navigate = useNavigate();

  useEffect(() => {
    //Track Page
    MixpanelTracking.instance().track(TrackingEvents.SearchPage);
  }, []);

  useEffect(() => {
    if (!debouncedSearchText.length) {
      setSearchResult(null);
      setIsSearching(false);
      return;
    }

    searchData(debouncedSearchText);
  }, [debouncedSearchText]);

  useEffect(() => {
    if (!debouncedSearchText.length && !!locationStore.coords) {
      searchData('');
    }
  }, [debouncedSearchText, locationStore.coords]);

  const handleSearchTrigger = (searchPayload: IMasterFilterSearchPayload) => {
    if (searchPayload.query) {
      return onSearchChange(searchPayload.query);
    }

    searchData('', searchPayload.coords);
  };

  const searchData = async (query: string, coords?: ICoordinates) => {
    try {
      setIsSearching(true);

      const _query = query.replaceAll('#', '');
      const results = await masterSearch(_query, {
        lat: coords?.lat ?? locationStore.coords?.lat,
        lng: coords?.lng ?? locationStore.coords?.lng
      });

      const hashtags = results.hashtagGroupedResults
        .filter(
          (hash) => hash.groupedMediaFiles.length > 0 || hash.gigs.length > 0
        )
        .map((hashtag) => ({
          name: hashtag.name,
          mediaFiles: hashtag.groupedMediaFiles.map<IVideoModel>(
            (video) => new GroupedVideoModel(video)
          ),
          events: hashtag.gigs.map(
            (experience) => new ExperienceModel(experience)
          )
        }));

      setSearchResult({
        events: results.eventResults.map(
          (experience) => new ExperienceModel(experience)
        ),
        clips: results.videoGroupedResults.map(
          (video) => new GroupedVideoModel(video)
        ),
        people: results.providerResults,
        hashtags
      });

      if (!tab) setSearchType('all');

      // //Track Search
      // if (query) {
      //   MixpanelTracking.instance().track(`Search`, {
      //     'Search Criteria': query
      //   });
      // }
    } catch (e: any) {
      const error = e.response?.data?.description || DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleTabPress = (tabName: keyof ISearchHolder) => () => {
    setSearchType(tabName);
    navigate(`/search?query=${debouncedSearchText}&t=${tabName}`, {
      replace: true
    });
  };

  const searchContent = useMemo<ISearchContent>(
    () => ({
      all: (
        <>
          <Section
            title='Events'
            className='mb-2'
            action={
              searchResult?.events?.length > 10 &&
              (() => setSearchType('events'))
            }
          >
            <GigGallery
              data={searchResult?.events.slice(0, 10)}
              placeholder='There are no event.'
              itemCount={10}
              onPressVideo={(idx) => {
                openExperienceModal({
                  media: searchResult.events,
                  startAtIndex: idx
                });
              }}
            />
          </Section>

          <Section
            title='Clips'
            action={
              searchResult?.clips?.length > 16 &&
              (() => {
                setSearchType('clips');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              })
            }
          >
            <VideoGallery
              data={searchResult?.clips.slice(0, 16)}
              itemCount={16}
              renderItem={(video, idx) => {
                return (
                  <VideoCard
                    key={`video-${video.id}`}
                    video={video}
                    onPress={() => {
                      openVideoModal({
                        media: searchResult.clips,
                        startAtIndex: idx
                      });
                    }}
                  />
                );
              }}
            />
          </Section>
        </>
      ),
      experiences: (
        <GigGallery
          data={searchResult?.experiences}
          onPressVideo={(idx) => {
            openExperienceModal({
              media: searchResult.experiences,
              startAtIndex: idx
            });
          }}
        />
      ),
      events: (
        <GigGallery
          data={searchResult?.events}
          onPressVideo={(idx) => {
            openExperienceModal({
              media: searchResult.events,
              startAtIndex: idx
            });
          }}
        />
      ),
      clips: (
        <VideoGallery
          data={searchResult?.clips}
          renderItem={(video, idx) => {
            return (
              <VideoCard
                key={`video-${video.id}`}
                video={video}
                onPress={() => {
                  openVideoModal({
                    media: searchResult.clips,
                    startAtIndex: idx
                  });
                }}
              />
            );
          }}
        />
      ),
      people: (
        <DataList
          data={searchResult?.people}
          renderItem={(creator, idx) => {
            return (
              <UserCard key={creator.id} user={creator} className='mb-3' />
            );
          }}
        />
      ),
      hashtags: searchResult?.hashtags?.map((hashtag) => (
        <Section
          key={`hashtag-${hashtag.name}`}
          title={`#${hashtag.name}`}
          className='mb-3'
          titleClassName='Search__hashtagTitle'
        >
          <Section title='Clips' className='mb-3'>
            <VideoGallery
              className='mb-3'
              data={hashtag.mediaFiles}
              placeholder='No clips found.'
              renderItem={(video, idx) => {
                return (
                  <VideoCard
                    key={`hashtag-video-${video.id}`}
                    video={video}
                    onPress={() => {
                      openVideoModal({
                        media: hashtag.mediaFiles,
                        startAtIndex: idx
                      });
                    }}
                  />
                );
              }}
            />
          </Section>
          <Section title='Events' className='mb-3'>
            <GigGallery
              data={hashtag.events}
              placeholder='No events found.'
              onPressVideo={(idx) => {
                openExperienceModal({
                  media: hashtag.events,
                  startAtIndex: idx
                });
              }}
            />
          </Section>
        </Section>
      ))
    }),
    [searchResult]
  );

  return (
    <div className='Search'>
      <Header
        subSection={
          <>
            <div className='Search__inputWrapper'>
              <MasterFilter
                searchQuery={debouncedSearchText}
                showFilterControls={false}
                onSearchTrigger={handleSearchTrigger}
              />
            </div>

            <div className='Search__tabs mt-3'>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'all'
                })}
                onClick={handleTabPress('all')}
                disabled={isSearching}
              >
                All
              </button>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'events'
                })}
                onClick={handleTabPress('events')}
                disabled={isSearching}
              >
                Events
              </button>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'clips'
                })}
                onClick={handleTabPress('clips')}
                disabled={isSearching}
              >
                Clips
              </button>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'people'
                })}
                onClick={handleTabPress('people')}
                disabled={isSearching}
              >
                Accounts
                {searchResult?.people?.length > 0 &&
                  searchType !== 'people' && (
                    <span className='Search__tabBadge'></span>
                  )}
              </button>
              <button
                className={classNames('Search__tab', {
                  'Search__tab--active': searchType === 'hashtags'
                })}
                onClick={handleTabPress('hashtags')}
                disabled={isSearching}
              >
                Tags
                {searchResult?.hashtags?.length > 0 &&
                  searchType !== 'hashtags' && (
                    <span className='Search__tabBadge'></span>
                  )}
              </button>
            </div>
          </>
        }
      />

      <div className='Search__container mb-5 px-3 px-lg-4'>
        <div className='Search__content'>
          {isSearching ? (
            <Loader fixed={false} />
          ) : !searchResult && !isSearching ? (
            EmptyList
          ) : searchResult && searchResult[searchType]?.length === 0 ? (
            <PlaceHolderCard label='We searched far and wide. Unfortunately, no results here.' />
          ) : (
            searchContent[searchType]
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Search);

import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import './SearchInput.scss';
import { ChangeEventHandler, forwardRef } from 'react';

interface Props {
  value: string;
  className?: string;
  onChange: (v?: string) => void;
  placeholderTxt?: string;
}

const SearchInput = forwardRef<HTMLInputElement, Props>(
  ({ className = '', value, onChange, placeholderTxt }, ref) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e.target.value);
    };

    return (
      <div className={`SearchInput ${className}`}>
        <div className='SearchInput__inputWrapper'>
          <div className='SearchInput__searchIcon'>
            <SearchIcon />
          </div>
          <input
            ref={ref}
            type='text'
            value={value}
            onChange={handleChange}
            className='SearchInput__input'
            placeholder={placeholderTxt ?? 'Search anything'}
          />
        </div>
      </div>
    );
  }
);

export default SearchInput;

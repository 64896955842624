import { coupons } from './coupons';
import { discovery } from './discovery';
import { products } from './products';

const api = {
  coupons,
  discovery,
  products
};

export default api;

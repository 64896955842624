import CreatorBadge from '../../CreatorBadge/CreatorBadge';
import { ReactComponent as HooplaLogo } from '../../../images/logo_sm.svg';
import './VideoCard.scss';
import classNames from 'classnames';
import ViewsBadge from '../../ViewsBadge/ViewsBadge';
import { IVideoModel } from '../../../core/types';
import HoverVideoPlayer from '../../HoverVideoPlayer/HoverVideoPlayer';
import LazyLoad from 'react-lazyload';
import usePreloadVideoInView from '../../../hooks/usePreloadVideoInView';

interface IProps {
  video: IVideoModel;
  className?: string;
  onPress: React.MouseEventHandler;
  type?: 'compact' | 'profile';
}

const VideoCard = ({ video, onPress, className = '', type }: IProps) => {
  const url = video.mediaFiles[0]?.isVideo ? video.mediaFiles[0]?.url : null;
  const { viewRef } = usePreloadVideoInView({ url });

  return (
    <div
      ref={viewRef}
      className={classNames(`VideoCard ${className}`, {
        'VideoCard__type--compact': type === 'compact',
        'VideoCard__type--profile': type === 'profile'
      })}
    >
      <div className='VideoCard__container'>
        <CreatorBadge className='VideoCard__user' user={video.owner} />
        <ViewsBadge
          className='VideoCard__views'
          viewsCount={video.views}
          reverse
        />
        <HoverVideoPlayer
          className='VideoCard__hoverVideo'
          videoSrc={video.thumbnails.video}
          pausedOverlay={
            video.thumbnails?.static ? (
              <LazyLoad height={266} once>
                <img
                  className='VideoCard__thumbnail'
                  src={video.thumbnails.static || video.thumbnails.animated}
                  alt={video.description}
                />
              </LazyLoad>
            ) : (
              <div className='VideoCard__placeholder'>
                <HooplaLogo />
              </div>
            )
          }
          onClick={onPress}
        />
      </div>
    </div>
  );
};

export default VideoCard;

export enum CouponDiscountType {
  percentage,
  amount
}

export enum BannerType {
  Generic,
  Profile,
  Category,
  HashtagEvents,
  Highlight,
  HeroBanner,
  SecondaryBanner
}

import { useInView } from 'react-intersection-observer';
import { useEffect, useRef } from 'react';

interface IProps {
  url?: string;
  threshold?: number;
}

const usePreloadVideoInView = ({ url, threshold = 0.5 }: IProps) => {
  const { ref, inView } = useInView({ threshold });
  const videoRef = useRef<HTMLVideoElement>(null);

  //Preload the main video if it has one and card is in view
  useEffect(() => {
    if (!url) return;

    if (inView) {
      if (videoRef.current) return;

      const videoUrl = url;
      const video = document.createElement('video');
      video.src = videoUrl;
      video.preload = 'auto';

      videoRef.current = video;

      return;
    }

    videoRef.current?.remove();

    return () => {
      videoRef.current?.remove();
    };
  }, [inView, url]);

  return {
    viewRef: ref,
    videoRef
  };
};

export default usePreloadVideoInView;

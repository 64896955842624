import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../SearchInput/SearchInput';
import { RoutesEnum } from '../../core/enums';
import HyperLocal from '../HyperLocal/HyperLocal';
import Button from '../Button/Button';
import SearchFilters, {
  ISearchFilter
} from '../Modal/SearchFilters/SearchFilters';
import { useDebounce, useOnClickOutside, useStores } from '../../hooks';
import { generateFormattedAddress, getCurrentCoords } from '../../core/helpers';
import { MY_LOCATION } from '../../core/consts';
import { ReactComponent as FilterIcon } from '../../icons/filter.svg';
import './MasterFilter.scss';
import classNames from 'classnames';
import { ICoordinates } from '../../core/types';

export interface IMasterFilterSearchPayload {
  query?: string;
  coords?: ICoordinates;
}

interface IProps {
  className?: string;
  searchQuery?: string;
  showFilterControls?: boolean;
  onSearchTrigger?: (searchPayload: IMasterFilterSearchPayload) => void;
}

const MasterFilter = ({
  className = '',
  searchQuery,
  showFilterControls = true,
  onSearchTrigger
}: IProps) => {
  const navigate = useNavigate();
  const { appDataManager, locationStore, userStore } = useStores();
  const [query, setQuery] = useState(searchQuery ?? '');
  const trimmed = query.trim();
  const debouncedText = useDebounce(trimmed.length >= 3 ? query : '', 500);
  const [hyperLocalValue, setHyperLocalValue] = useState(
    locationStore.address ?? ''
  );
  const [filters, setFilters] = useState<ISearchFilter>(
    appDataManager.data.filters
  );
  const [showFilters, setShowFilter] = useState(false);
  const filtersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!debouncedText) {
      return;
    }

    if (onSearchTrigger) {
      return onSearchTrigger({ query: debouncedText });
    }

    navigate(RoutesEnum.Search + '?query=' + debouncedText);
  }, [debouncedText]);

  const handleToggleHyperLocal = () => {
    /**
     * Request and update user location if he has granted permissions
     */
    //if (!showHyperLocal) {
    getCurrentCoords(
      async (coords) => {
        //Update hyper local coords if user has selected my location
        if (appDataManager.data?.hyperLocal?.fullAddress === MY_LOCATION) {
          locationStore.updateCoords(appDataManager.data.hyperLocal?.coords);
        }

        const addressData = await generateFormattedAddress(coords);

        userStore.saveAddress(
          addressData.formattedAddress,
          addressData.addressResult,
          addressData.latLng
        );
      },
      () => {}
    );
    //  }

    setShowFilter(false);
  };

  const handleToggleFilters = () => {
    setShowFilter(!showFilters);
  };

  useOnClickOutside(filtersRef, () => {
    setShowFilter(false);
  });

  return (
    <div className={`MasterFilter ${className}`}>
      <SearchInput
        className='MasterFilter__search'
        value={query}
        onChange={(value) => {
          if (!value) {
          }

          setQuery(value);
        }}
        // onClickHyperLocal={handleToggleHyperLocal}
      />

      <div className='MasterFilter__separator'></div>

      <div className='MasterFilter__hyperLocal'>
        <HyperLocal
          value={hyperLocalValue}
          onValueChange={(text) => setHyperLocalValue(text)}
          onSearchResults={(results) => {}}
          onSelectLocation={async ({ fullAddress }, coords) => {
            setHyperLocalValue(fullAddress);
            locationStore.saveLocation(fullAddress, coords);
            appDataManager.updateHyperLocalData(fullAddress, coords);

            if (onSearchTrigger) {
              return onSearchTrigger({ coords });
            }

            navigate(RoutesEnum.Search);
          }}
          onClearSelection={() => {
            setHyperLocalValue('');
            locationStore.saveLocation(undefined, undefined);
            appDataManager.updateHyperLocalData(undefined, undefined);
          }}
        />
      </div>

      <div ref={filtersRef} className='MasterFilter__filterButton'>
        {showFilterControls && (
          <Button
            className={classNames('MasterFilter__controlButton', {
              'MasterFilter__hyperLocalButton--active': showFilters
            })}
            variant='icon'
            icon={
              <FilterIcon
                className={classNames('MasterFilter__controlIcon', {
                  'MasterFilter__controlIcon--active': showFilters
                })}
              />
            }
            onClick={handleToggleFilters}
          />
        )}
        {showFilters && (
          <SearchFilters
            className='MasterFilter__filters'
            values={filters}
            onChange={(selectedFilters) => {
              setFilters(selectedFilters);
              appDataManager.updateFilters(selectedFilters);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MasterFilter;

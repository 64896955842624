import { AxiosResponse } from 'axios';
import axios from './axios';
import { AddEditPromotionCodeRequest, PromotionCode } from './interfaces';

axios.defaults.headers.post['Api-Version'] = 2;

export const coupons = {
  /**
   * Create/Update a promotion codes
   * @param payload
   */
  create: async (payload: AddEditPromotionCodeRequest) => {
    const response: AxiosResponse = await axios.post(
      '/PromotionCodes/AddEditPromotionCodes',
      payload
    );

    return response.data;
  },
  /**
   * Get promotion codes for event
   * @param eventId
   */
  getForEvent: async (eventId: number) => {
    const response: AxiosResponse<PromotionCode[]> = await axios.get(
      `/PromotionCodes/GetPromotionCodes/${eventId}`,
      {
        headers: {
          'Api-Version': 2
        }
      }
    );

    return response.data;
  }
};

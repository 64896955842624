import { AxiosResponse } from 'axios';
import axios from './axios';
import { ListProviderProductsResponse } from './models';

export const products = {
  /**
   * Get event linked products
   * @param eventId
   * @returns Array ListProviderProductsResponse
   */
  getEventProducts: async (eventId: number) => {
    const response: AxiosResponse<ListProviderProductsResponse[]> =
      await axios.get('/Products/event/list', {
        params: {
          eventId
        }
      });

    return response.data;
  }
};
